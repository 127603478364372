import {yupResolver} from '@hookform/resolvers/yup';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import React from 'react';
import {
    useForm,
    Controller,
    SubmitHandler
} from 'react-hook-form';
import AnalyticsDistributor from '@/distributors/AnalyticsDistributor';
import ApiDistributor from '@/distributors/APIDistributor';
import LocalesEnum from '@/enums/LocalesEnum';
import SectionsEnum from '@/enums/SectionsEnum';
import usePost from '@/hooks/handlers/usePost';
import useLocale from '@/hooks/useLocale';
import useStore from '@/store/index';
import {IProps as IButton} from '@/UI/form-control/button';
import {IProps as IInput} from '@/UI/form-control/input';
import {IProps as ITextarea} from '@/UI/form-control/textarea';
import {countries} from '@/utils/config';
import validation from './form.validation';
import styles from './form.module.css';

const Button = dynamic<IButton>(() => import('@/UI/form-control/button'));
const Input = dynamic<IInput>(() => import('@/UI/form-control/input'));
const Loader = dynamic(() => import('@/UI/loader'));
const Checkbox = dynamic(() => import('@/UI/form-control/checkbox'));
const Phone = dynamic(() => import('@/UI/form-control/input/phone'));
const Select = dynamic(() => import('@/UI/form-control/select'));
const Textarea = dynamic<ITextarea>(() => import('@/UI/form-control/textarea'));

type IValues = {
    [key: string]: string
}

const Form: React.FC = () => {
    const locale = useLocale();
    const router = useRouter();
    const order = useStore(state => state.order.value);
    const [phone, setPhone] = React.useState({number: '', fullNumber: ''});
    const [isPhoneValid, setPhoneValid] = React.useState(true);
    const schema = validation(locale);
    const {
        control,
        formState: {
            isSubmitting,
            errors,
        },
        reset,
        setValue,
        setError,
        handleSubmit,
    } = useForm<IValues>({
        resolver: yupResolver(schema),
        mode: 'onTouched',
    });
    const onSuccess = async () => {
        if (
            window.gtag
            && process.env.NEXT_PUBLIC_VERCEL_ENV
            && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ) {
            window.gtag('event', 'conversion', {send_to: AnalyticsDistributor[router.locale || LocalesEnum.cs]?.send});
        }

        reset();
        setPhone({number: '', fullNumber: ''});
    };
    const {handlePost} = usePost<IValues>(onSuccess);
    const onSubmit: SubmitHandler<IValues> = async values => {
        if (!values.agreement) {
            setError('agreement', {type: 'agreement', message: locale?.form?.input?.agreement?.required});

            return;
        }

        if (values.med) {
            return;
        }

        const body = {
            ...values,
            phone: phone.fullNumber,
        };

        await handlePost(ApiDistributor.send, body);
    };
    const handlePhone = (number: string, fullNumber: string, valid: boolean) => {
        setPhoneValid(valid);
        setPhone({number, fullNumber});
    };

    React.useEffect(() => {
        setValue('order', order ? locale.types.find(item => item.key === order)?.value || '' : '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    return (
        <section id={SectionsEnum.form}>
            <h3 className={styles.heading}>
                {locale.sections.form.title}
            </h3>
            <form
                className={`${styles.form} ${isSubmitting ? styles.submitting : ''}`}
                onSubmit={handleSubmit(onSubmit)}
            >
                {isSubmitting && <Loader className={styles.loader} />}
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Controller
                            name="name"
                            control={control}
                            render={({field}) => (
                                <Input
                                    {...field}
                                    required
                                    label={locale.form.input.name.label}
                                    disabled={isSubmitting}
                                    error={errors.name?.message}
                                    className={styles.spacing}
                                />
                            )}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <Controller
                            name="email"
                            control={control}
                            render={({field}) => (
                                <Input
                                    {...field}
                                    required
                                    label={locale.form.input.email.label}
                                    disabled={isSubmitting}
                                    error={errors.email?.message}
                                    className={styles.spacing}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Controller
                            name="company"
                            control={control}
                            render={({field}) => (
                                <Input
                                    {...field}
                                    label={locale.form.input.company.label}
                                    disabled={isSubmitting}
                                    error={errors.company?.message}
                                    className={styles.spacing}
                                />
                            )}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <Phone
                            name="phone"
                            label={locale.form.input.phone.label}
                            value={phone.fullNumber}
                            disabled={isSubmitting}
                            onlyCountries={Object.values(countries)}
                            defaultCountry={router.locale ? countries[router.locale] : 'cz'}
                            error={!isPhoneValid ? locale?.form?.input.phone.error : ''}
                            className={styles.spacing}
                            onChange={handlePhone}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Controller
                            name="order"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    label={locale.form.input.order.label}
                                    disabled={isSubmitting}
                                    defaultLabel={locale.form.select.default}
                                    values={locale.types.map(item => ({
                                        value: item.value,
                                        label: item.value,
                                    })) || []}
                                    className={styles.spacing}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Controller
                            name="message"
                            control={control}
                            render={({field}) => (
                                <Textarea
                                    {...field}
                                    label={locale.form.input.message.label}
                                    disabled={isSubmitting}
                                    maxlength={280}
                                    error={errors.message?.message}
                                    className={styles.spacing}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Controller
                            name="agreement"
                            control={control}
                            render={({field}) => (
                                <Checkbox
                                    {...field}
                                    label={locale.form.input.agreement.label}
                                    disabled={isSubmitting}
                                    checked={!!field.value}
                                    error={errors.agreement?.message}
                                    className={styles.agreement}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Controller
                            name="med"
                            control={control}
                            render={({field}) => (
                                <input
                                    {...field}
                                    type="text"
                                    autoComplete="off"
                                    tabIndex={-1}
                                    className={styles.hidden}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Button
                            type="submit"
                            className={styles.button}
                            disabled={isSubmitting}
                        >
                            {locale.form.submit}
                        </Button>
                    </div>
                </div>
            </form>
            <p className={styles.paragraph}>
                {locale.form.paragraph.heading}<br />
                <strong>{locale.form.paragraph.phone}</strong>
            </p>
        </section>
    );
};

export default Form;
